<template>
  <v-container>
    <AnalyticsMain />
  </v-container>
</template>
<script>
import AnalyticsMain from '@/components/analytics/AnalyticsMain'

export default {
  name: 'Analytics',
  components: {
    AnalyticsMain
  }
}
</script>
