<template>
  <v-row>
    <v-col
      class="mt-2"
      cols="12"
      sm="6"
      lg="3"
      v-for="(stat, i) in stats"
      :key="stat.name"
    >
      <v-card min-height="130">
        <v-row>
          <v-col class="ml-2 mr-n8 justify-center align-center">
            <v-card-title class="primary--text text-h3">
              {{
                i === 0
                  ? tweenedNumber0.toFixed(0)
                  : i === 1
                  ? tweenedNumber1.toFixed(0)
                  : i === 2
                  ? tweenedNumber2.toFixed(0)
                  : tweenedNumber3.toFixed(0)
              }}
            </v-card-title>
          </v-col>
          <v-col>
            <v-card-subtitle class="primary--text font-weight-bold">{{
              stat.name
            }}</v-card-subtitle>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import gsap from 'gsap'
import { 
  CognitoIdentityProviderClient,
  ListGroupsCommand,
  ListUsersCommand,
  DescribeUserPoolCommand,
  ListUsersInGroupCommand
} from "@aws-sdk/client-cognito-identity-provider";

const { CognitoIdentityClient } = require('@aws-sdk/client-cognito-identity')
const {
  fromCognitoIdentityPool
} = require('@aws-sdk/credential-provider-cognito-identity')

const REGION = "us-west-2"

export default {
  name: 'AnalyticsStat',
  data() {
    return {
      number0: 0,
      tweenedNumber0: 0,
      number1: 1,
      tweenedNumber1: 0,
      number2: 0,
      tweenedNumber2: 0,
      number3: 0,
      tweenedNumber3: 0,
      stats: [
        // Given temporary values so the promise can run without crashing the app
        { name: 'Number of Groups', value: 0 },
        { name: 'Number of different institutions', value: 0 },
        { name: 'Estimated Number of Real Users', value: 0 },
        { name: 'Number of Admins', value: 0 }
      ],
      num_users: 0,
      institutions: null
    }
  },
  watch: {
    number0(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: 'circ.out',
        tweenedNumber0: newValue
      })
    },
    number1(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: 'circ.out',
        tweenedNumber1: newValue
      })
    },
    number2(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: 'circ.out',
        tweenedNumber2: newValue
      })
    },
    number3(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: 'circ.out',
        tweenedNumber3: newValue
      })
      // Need to check if promise was fulfilled yet
      if (this.num_users != 0) {
        // The 9 comes from a calibration count done on 08/12/2021. We counted 9 non-admin BioDF members
        this.number2 = this.num_users - newValue - 9
      }
    }
  },
  created() {
    this.number0 = this.stats[0].value
    this.number1 = this.stats[1].value
    this.number2 = this.stats[2].value
    this.number3 = this.stats[3].value
    this.updateStats()
  },
  methods: {
    updateStats() {
      // Register a new client
      let cognitoProviderName = "cognito-idp." + REGION + ".amazonaws.com/" + process.env.VUE_APP_COGNITO_POOL_ID
      const client = new CognitoIdentityProviderClient({
        region: REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_USERSTATS,
          logins: {
            [`${cognitoProviderName}`]: this.$store.state.user.session.idToken.jwtToken
          }
        })
      });
  
      // Get number of groups from AWS (Admin included)
      var input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID
      }
      var command = new ListGroupsCommand(input);
      client.send(command).then(data => {
        this.number0 = data.Groups.length
      })
      
      // Get users in userpool from AWS for counting institutions
      var input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID
      }
      var command = new ListUsersCommand(input);
      this.institutions = new Set();
      client.send(command).then(data => {
        for (let i = 0; i < data.Users.length; i++) {
          // Organization was not a mandatory field, so some may be blank
          for (let j = 0; j < data.Users[i].Attributes.length; j++) {
            if (data.Users[i].Attributes[j].Name == "custom:organization") {
              this.institutions.add(data.Users[i].Attributes[j].Value);
              break;
            }
          }
        }
        if (data.PaginationToken != undefined) {
          this.count_institutions(client, data.PaginationToken)
        } else {
          this.number1 = this.institutions.size
        }
      });

      // Get description of userpool from AWS
      var input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID
      }
      var command = new DescribeUserPoolCommand(input);
      client.send(command).then(data => {
        this.num_users = data.UserPool.EstimatedNumberOfUsers
        this.number2 = this.num_users
      })
      
      // Get description of admin group from AWS
      var input = {
        GroupName: "Admin",
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID
      }
      var command = new ListUsersInGroupCommand(input);
      client.send(command).then(data => {
        this.number3 = data.Users.length
      })
    },
    count_institutions(client, paginationToken) {
      var input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
        PaginationToken: paginationToken
      }
      var command = new ListUsersCommand(input);
      client.send(command).then(data => {
        for (let i = 0; i < data.Users.length; i++) {
          // Organization was not a mandatory field, so some may be blank
          for (let j = 0; j < data.Users[i].Attributes.length; j++) {
            if (data.Users[i].Attributes[j].Name == "custom:organization") {
              this.institutions.add(data.Users[i].Attributes[j].Value);
              break;
            }
          }
        }
        if (data.PaginationToken != undefined) {
          this.count_institutions(client, data.PaginationToken)
        } else {
          this.number1 = this.institutions.size
        }
      })
    }
  }
}
</script>
