<template>
  <v-row>
    <v-col
      class="mt-2"
      cols="12"
      sm="6"
      lg="3"
      v-for="(stat, i) in stats"
      :key="stat.name"
    >
      <v-card min-height="130">
        <v-row>
          <v-col class="ml-2 mr-n8 justify-center align-center">
            <v-card-title
              :class="getNumber(i) > 0 ? 'error--text text-h3' : 'primary--text text-h3'"
            >
              {{ getNumber(i) }}
            </v-card-title>
          </v-col>
          <v-col>
            <v-card-subtitle class="primary--text font-weight-bold">{{
              stat.name
            }}</v-card-subtitle>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-dialog v-model="deleteSubprojectsDialog" width="450">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          class="ml-4 mt-2"
          height="130"
          width="420"
          :disabled="deleteButtonDisabled"
          v-bind="attrs"
          v-on="on"
          ><h3>Delete Orphan SubProjects</h3></v-btn
        >
      </template>
      <Dialog
        @close-dialog="deleteSubprojectsDialog = false"
        heading="Confirm Delete"
      >
        <v-card-text>
          <h3>
            <b>
              Are you sure you want to permanently delete all orphan
              subprojects? This action is irreversible.
            </b>
          </h3>
        </v-card-text>

        <v-btn
          color="primary"
          class="mr-8"
          width="100"
          @click="deleteSubprojectsDialog = false"
        >
          Cancel
        </v-btn>
        <v-btn color="error" width="100" @click="deleteOrphanSubProjects">
          <v-progress-circular
            indeterminate
            size="24"
            v-if="deletionInProgress"
          ></v-progress-circular>
          <div v-else>
            Delete
          </div>
        </v-btn>
      </Dialog>
    </v-dialog>
  </v-row>
</template>

<script>
import gsap from "gsap";
import Dialog from "@/components/Dialog";
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";

const { CognitoIdentityClient } = require("@aws-sdk/client-cognito-identity");
const {
  fromCognitoIdentityPool,
} = require("@aws-sdk/credential-provider-cognito-identity");
const REGION = "us-west-2";

export default {
  name: "AnalyticsQualityControlStat",
  components: {
    Dialog,
  },
  data() {
    return {
      deleteSubprojectsDialog: false,
      deleteButtonDisabled: true,
      deletionInProgress: false,
      number0: 0,
      tweenedNumber0: 0,
      number1: 1,
      tweenedNumber1: 0,
      number2: 0,
      tweenedNumber2: 0,
      stats: [
        // Given temporary values so the promise can run without crashing the app
        { name: "Orphan Comparison SubProjects", value: 0 },
        { name: "Orphan Phred SubProjects", value: 0 },
        { name: "Orphan PhyloTree SubProjects", value: 0 },
      ],
    };
  },
  watch: {
    number0(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        tweenedNumber0: newValue,
      });
    },
    number1(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        tweenedNumber1: newValue,
      });
    },
    number2(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        tweenedNumber2: newValue,
      });
    },
  },
  created() {
    this.number0 = this.stats[0].value;
    this.number1 = this.stats[1].value;
    this.number2 = this.stats[2].value;
    this.updateStats();
  },
  methods: {
    getNumber(i) {
      if (i === 0) {
        return this.tweenedNumber0.toFixed(0);
      } else if (i === 1) {
        return this.tweenedNumber1.toFixed(0);
      } else {
        return this.tweenedNumber2.toFixed(0);
      }
    },
    updateStats() {
      let cognitoProviderName =
        "cognito-idp." +
        REGION +
        ".amazonaws.com/" +
        process.env.VUE_APP_COGNITO_POOL_ID;
      const lambda = new LambdaClient({
        region: REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_LAMBDA,
          logins: {
            [`${cognitoProviderName}`]: this.$store.state.user.session.idToken
              .jwtToken,
          },
        }),
      });

      const params = {
        FunctionName: "Monitor-Orphan-SubProjects",
        InvocationType: "RequestResponse",
        LogType: "None",
        Payload: JSON.stringify({
          env: process.env.VUE_APP_ENV,
          // we don't want to send emails when this function is invoked through the website
          notifyAdmins: false,
        }),
      };

      lambda
        .send(new InvokeCommand(params))
        .then((data) => {
          // convert the Payload from Uint8Array format to JSON format
          const jsonString = Buffer.from(data.Payload).toString("utf8");
          const parsedData = JSON.parse(jsonString);
          console.log(parsedData);

          this.number0 = parsedData.comparisonOrphanCount;
          this.number1 = parsedData.phredOrphanCount;
          this.number2 = parsedData.phylotreeOrphanCount;

          // enable the delete button if there are any orphans, otherwise disable it
          if (this.number0 > 0 || this.number1 > 0 || this.number2 > 0) {
            this.deleteButtonDisabled = false;
          } else {
            this.deleteButtonDisabled = true;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    deleteOrphanSubProjects() {
      // first set deletionInProgress to true
      this.deletionInProgress = true;

      let cognitoProviderName =
        "cognito-idp." +
        REGION +
        ".amazonaws.com/" +
        process.env.VUE_APP_COGNITO_POOL_ID;
      const lambda = new LambdaClient({
        region: REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_LAMBDA,
          logins: {
            [`${cognitoProviderName}`]: this.$store.state.user.session.idToken
              .jwtToken,
          },
        }),
      });

      const params = {
        FunctionName: "Delete-Orphan-SubProjects",
        InvocationType: "RequestResponse",
        LogType: "None",
        Payload: JSON.stringify({
          env: process.env.VUE_APP_ENV,
        }),
      };

      lambda
        .send(new InvokeCommand(params))
        .then((data) => {
          // update the stats, close the dialog, and set deletionInProgress to false
          this.updateStats();
          this.deleteSubprojectsDialog = false;
          this.deletionInProgress = false;

          const notification = {
            type: "success",
            message: "Successfully deleted all orphan subprojects",
          };
          this.$store.dispatch("addNotification", notification);
        })
        .catch((err) => {
          console.error(err); // DEBUG
          // update the stats, close the dialog, and set deletionInProgress to false
          this.updateStats();
          this.deleteSubprojectsDialog = false;
          this.deletionInProgress = false;

          const notification = {
            type: "error",
            message: err.message,
          };
          this.$store.dispatch("addNotification", notification);
        });
    },
  },
};
</script>
