<template>
  <div>
    <v-row justify="center" class="my-2">
      <v-img max-width="300" src="@/assets/biodf/BioDF-textlogo.png"></v-img>
    </v-row>
    <v-row justify="center">
      <p class="primary--text">Welcome to the Admin Console</p>
    </v-row>

    <v-card-title class="primary--text font-weight-bold mt-5"
      >Phred</v-card-title
    >
    <DashboardStat />

    <v-card-title class="primary--text font-weight-bold mt-5"
      >Users</v-card-title
    >
    <AnalyticsUserStat />

    <v-card-title class="primary--text font-weight-bold mt-5"
      >Admin</v-card-title
    >
    <AnalyticsAdminStat />

    <v-card-title class="primary--text font-weight-bold mt-5"
      >Quality Control</v-card-title
    >
    <AnalyticsQualityControlStat />
  </div>
</template>

<script>
import DashboardStat from "../dashboard/DashboardStat";
import AnalyticsUserStat from "./AnalyticsUserStat";
import AnalyticsAdminStat from "./AnalyticsAdminStat";
import AnalyticsQualityControlStat from "./AnalyticsQualityControlStat.vue";

export default {
  name: "AnalyticsMain",
  components: {
    DashboardStat,
    AnalyticsUserStat,
    AnalyticsAdminStat,
    AnalyticsQualityControlStat,
  },
};
</script>
